import { createReducer, on } from '@ngrx/store';
import { devices } from './iot-devices.models';
import { resetDevicesState, updatedeviceSuccess } from './iot-devices.actions';
import {
  getDevicessFailure,
  getDevicessSuccess,
  deletedeviceSuccess,
} from './iot-devices.actions';
import {
  CreatedeviceFailure,
  CreatedeviceSuccess,
  createdevice,
  GetAlldevice,
  getAlldevicesSuccess,
  getAlldevicesFailure,
  getDevices,
} from './iot-devices.actions';

export interface DevicesState {
  device: devices | null;
  createdeviceError: any;
  getDevicesResponse: any;
  createdeviceResponse: any;
  getAlldeviceResponse: any;
  deletedeviceResponse: any;
  updatedeviceResponse: any;
}

const initialState: DevicesState = {
  device: null,
  createdeviceError: null,
  getDevicesResponse: null,
  createdeviceResponse: null,
  getAlldeviceResponse: null,
  deletedeviceResponse: null,
  updatedeviceResponse: null,
};

export const DevicesReducer = createReducer(
  initialState,
  on(getDevices, (state) => ({ ...state })),
  on(createdevice, (state) => ({ ...state, error: null })),
  on(CreatedeviceSuccess, (state, { createdeviceResponse }) => ({
    ...state,
    createdeviceResponse,
  })),
  on(CreatedeviceFailure, (state, { createdeviceError }) => ({
    ...state,
    createdeviceError,
  })),
  on(getDevicessSuccess, (state, { getDevicesResponse }) => ({
    ...state,
    getDevicesResponse,
  })),
  on(getDevicessFailure, (state, { getDevicesError }) => ({
    ...state,
    getDevicesError,
  })),
  on(GetAlldevice, (state) => ({ ...state })),
  on(getAlldevicesSuccess, (state, { getAlldeviceResponse }) => ({
    ...state,
    getAlldeviceResponse,
  })),
  on(getAlldevicesFailure, (state, { getAlldeviceError }) => ({
    ...state,
    getAlldeviceError,
  })),
  on(deletedeviceSuccess, (state, { deletedeviceResponse }) => ({
    ...state,
    deletedeviceResponse,
  })),
  on(updatedeviceSuccess, (state, { updatedeviceResponse }) => ({
    ...state,
    updatedeviceResponse,
  })),
  on(resetDevicesState, () => initialState)
);
