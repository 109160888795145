import { UserProfile } from './../../shared/modals/common';
import {
  getUserProfile,
  refreshToken,
} from './../../store/Authentication/authentication.actions';
import {
  refreshTokenRes,
  userDetails,
} from './../../store/Authentication/authentication-selector';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/auth.service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { logout } from '../../store/Authentication/authentication.actions';
import { Store, select } from '@ngrx/store';
import { getError } from '../../store/Authentication/authentication-selector';
import { log } from 'console';
import { LoaderService } from '../../core/services/loader.service';
import { RpUserProfileComponent } from '../../features/users/rp-user-profile/rp-user-profile.component';
import { RpChangepasswordComponent } from '../../features/users/rp-changepassword/rp-changepassword.component';
import { SharedService } from '../../shared/shared.service';
import { Roles } from '../../shared/constants/enum';
import { MessagingService } from '../../core/services/message.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rp-header',
  templateUrl: './rp-header.component.html',
  styleUrl: './rp-header.component.scss',
})
export class RpHeaderComponent implements OnInit {
  mode: any;
  element: any;
  cookieValue: any;
  sesstionexpiresTime: number = 0;
  cutoffTime: number = 5 * 60;
  lastActivityTime!: number;
  token: any;
  refreshToken: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  theme: any;
  submitted: boolean = false;
  error$: any;
  activeInstance: any;
  profileData: UserProfile | undefined;
  newNotifications: any;
  totalNotifications: number = 3;
  userRole: any;
  listOfRoles = Roles;
  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private store: Store,
    public languageService: LanguageService,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private messagingService: MessagingService // public activeModal: NgbActiveModal
  ) {
    this.getStoreInfo();
    this.resetTimer();
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.token = this.currentUserData.token;
    this.refreshToken = this.currentUserData.refreshToken;
    if (this.currentUserData) {
      this.sessionExpiredTimecalculate();
    }
  }

  gotoDashBoard() {
    console.log(this.currentUserData);
    const role = this.currentUserData?.currentUserRoleDetails?.role;

    switch (role) {
      case Roles.ADMIN:
        this.router.navigate(['/admindashboard']);
        break;
      case Roles.SUPERADMIN:
        this.router.navigate(['/masters']);
        break;
      case Roles.CONSUMER:
        this.router.navigate(['/postpaidConsumerDashboard']);
        break;
      default:
        console.warn('Unknown role, no dashboard to navigate to.');
        return false;
    }

    return true;
  }

  resetTimer(): void {
    this.lastActivityTime = new Date().getTime();
    /*  let dt = new Date();
     this.lastActivityTime = Math.floor(dt.getTime()) */

    console.log(this.lastActivityTime);
  }
  // @HostListener('document:visibilitychange', ['$event'])
  // onVisibilityChange(event: any): void {
  //   if (document.visibilityState === 'visible') {
  //     console.log('Browser tab is focused');
  //     this.handleTabFocus();
  //   }
  // }
  // handleTabFocus() {
  //   // alert('Browser tab is focused');
  //   // Add additional logic here if needed
  // }

  sessionExpiredTimecalculate() {
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.token = this.currentUserData.token;
    this.refreshToken = this.currentUserData.refreshToken;
    //sesstion Time out
    let currentDateTime = new Date();
    let getDateTime = currentDateTime.getTime();

    const sessstionExpiresTime = new Date(this.currentUserData.tokenExpiresOn);
    // console.log(sessstionExpiresTime, 'sessstionExpiresTime');
    const expireTime =
      Math.floor(sessstionExpiresTime.getTime()) - this.lastActivityTime;
    console.log(expireTime, 'expireTime');
    let sessstionExpireTimeInMillisecounds = expireTime / 1000;
    // let sessstionExpireTimeInMillisecounds = 150
    console.log(sessstionExpireTimeInMillisecounds);
    let remainingTime = Math.floor(sessstionExpireTimeInMillisecounds);
    console.log(remainingTime, 'remaingTime');
    this.sesstionexpiresTime = remainingTime;
    console.log(this.sesstionexpiresTime, 'SesstionexpireTime');
    if (this.sesstionexpiresTime > 0) {
      this.startTimer();
    } else {
      this.logout();
    }
  }

  timerView: string = '';
  private timeSubscription: any;
  private startTimer(): void {
    this.timeSubscription = setInterval(() => {
      if (this.sesstionexpiresTime > 0) {
        // console.log(this.sesstionexpiresTime)
        //  console.log(this.cutoffTime)
        if (this.cutoffTime == this.sesstionexpiresTime) {
          // console.log('Sesstion is Expired');
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-primary ms-2',
            },
            buttonsStyling: false,
          });

          swalWithBootstrapButtons
            .fire({
              allowOutsideClick: false,
              title: 'Session out',
              text: `Your Session is going to Expire in the next 5 minutes!.`,
              icon: 'warning',
              confirmButtonText: 'Yes, Continue',
              cancelButtonText: 'No, Cancel',
              showCancelButton: true,
              timer: 20000, // close automatically after timeout
              timerProgressBar: true,
            })
            .then((result) => {
              console.log(result, 'result');
              if (result.value) {
                this.getrefreshToken();
              }
            });
        }

        this.sesstionexpiresTime--;
        this.cd.detectChanges();
      } else {
        clearInterval(this.timeSubscription);
        this.logout();
        console.log('Sesstion is Expired');
      }
    }, 1000);
  }

  private formateDigits(digits: number): string {
    return digits < 10 ? `0${digits}` : `${digits}`;
  }

  public formatTime(time: number) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor(time % 3600) / 60;
    const secounds = time % 60;

    return `${this.formateDigits(hours)}:${this.formateDigits(
      minutes
    )}:${this.formateDigits(secounds)}`;
  }

  getStoreInfo() {
    this.error$ = this.store.pipe(select(getError));
    let profileData$ = this.store.pipe(select(userDetails));

    this.error$.subscribe((res: any) => {
      console.log(res);
      this.loaderService.setLoading(false);
    });

    profileData$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      this.profileData = res;
      console.log('responseprofie', res);
    });
  }

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  listLang: any = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu!: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    this.modalRef = this.modalService.show(content);
  }

  openProfileModal() {
    this.modalRef = this.modalService.show(RpUserProfileComponent, {
      class: 'modal-lg',
    });
  }

  openChangePwdModal() {
    this.modalRef = this.modalService.show(RpChangepasswordComponent);
  }

  /**
   * Open center modal
   * @param centerDataModal center modal data
   */
  centerModal(centerDataModal: any) {
    this.modalRef = this.modalService.show(centerDataModal);
  }

  /**
   * Profile modal
   * @param profile center modal data
   */
  profileModal(profile: any) {
    this.modalRef = this.modalService.show(profile);
  }

  ngOnInit() {
    this.openMobileMenu = false;
    this.getUserInfo();
    this.getFcmNotifications();
    //this.startTimer()
  }
  getFcmNotifications() {
    this.newNotifications = this.messagingService.getNewNotification;
    this.messagingService.getNewNotification().subscribe((user) => {
      if (user && user.notification) {
        this.totalNotifications = this.totalNotifications + 1;
      }
    });
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    // this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    console.log('event');
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.loaderService.setLoading(true);
    this.store.dispatch(logout());
  }
  getrefreshToken() {
    let requestPayload = {
      token: this.token,
      refreshToken: this.refreshToken,
    };
    this.loaderService.setLoading(true);
    this.store.dispatch(refreshToken({ requestPayload: requestPayload }));
  }

  onSubmit() {
    this.submitted = true;
  }

  url: any = '';
  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event?.target?.result;
        console.log(this.url);
      };
    }
  }
  tokenExpiresOn!: Date;

  currentUserData: any;
  exitInstance(): void {
    // Your logic for exiting the instance goes here
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.project != null) {
      this.currentUserData.project = null;
      this.currentUserData.projectUtilities = [];
      localStorage.setItem('currentUser', JSON.stringify(this.currentUserData));
    }
    console.log('Exit Instance clicked');
    this.sharedService.updateRole(Roles.SUPERADMIN, false);
    this.router.navigate(['/dashboard']);
  }

  getUserInfo() {
    this.store.dispatch(getUserProfile());
    this.sharedService.getCurrentUser().subscribe((user) => {
      this.activeInstance = user.activeInstance;
      this.userRole = user?.currentUserRoleDetails?.role;
      setTimeout(() => {
        const currentUserString = localStorage.getItem('currentUser');
        this.currentUserData = JSON.parse(currentUserString ?? '');
      }, 100);
      console.log(this.currentUserData);
    });
    let getResidentilaUnitById$ = this.store.pipe(select(refreshTokenRes));
    getResidentilaUnitById$.subscribe((res) => {
      if (res) {
        this.sesstionexpiresTime = 0;
        this.sessionExpiredTimecalculate();

        console.log('timer Starat');
      }
    });
  }

  payment() {
    // this.paymentService.payUBuy().subscribe((arg) => {
    // const product = arg.info;

    const product = {
      payu_url: 'https://test.payu.in/_payment', // PayU payment URL for testing
      first_name: 'John',
      email: 'john.doe@example.com',
      mobile: '1234567890',
      call_back_url: 'https://example.com/payment-success', // Callback URL for successful payment
      payu_cancel_url: 'https://example.com/payment-cancel', // Callback URL for canceled payment
      payu_fail_url: 'https://example.com/payment-fail', // Callback URL for failed payment
      payu_merchant_key:
        'eabec285da28fd0e3054d41a4d24fe9f7599c9d0b66646f7a9984303fd6124044b6206daf831e9a8bda28a6200d318293a13d6c193109b60bd4b4f8b09c90972', // Replace with your actual merchant key
      payu_sha_token: 'your_sha_token', // Replace with your actual SHA token
      txnId: 't6svtqtjRdl4ws', // Unique transaction ID
      plan_name: 'Basic Plan', // Plan or product name
      amount: '50.00', // Amount to be paid
      service_provide: 'payu_paisa', // Service provider
    };

    const paymentDetails = {
      payu_url: product.payu_url,
      first_name: product.first_name,
      email: product.email,
      mobile: product.mobile,
      callback_url: product.call_back_url,
      payu_cancel_url: product.payu_cancel_url,
      payu_fail_url: product.payu_fail_url,
      payu_merchant_key: product.payu_merchant_key,
      payu_sha_token: product.payu_sha_token,
      txnid: product.txnId,
      plan_name: product.plan_name,
      amount: product.amount,
      service_provider: product.service_provide,
    };

    let paymentString = `
          <html>
            <body>
              <form action="${paymentDetails.payu_url}" method="post" id="payu_form">
                <input type="hidden" name="firstname" value="${paymentDetails.first_name}"/>
                <input type="hidden" name="email" value="${paymentDetails.email}"/>
                <input type="hidden" name="phone" value="${paymentDetails.mobile}"/>
                <input type="hidden" name="surl" value="${paymentDetails.callback_url}"/>
                <input type="hidden" name="curl" value="${paymentDetails.payu_cancel_url}"/>
                <input type="hidden" name="furl" value="${paymentDetails.payu_fail_url}"/>
                <input type="hidden" name="key" value="${paymentDetails.payu_merchant_key}"/>
                <input type="hidden" name="hash" value="${paymentDetails.payu_sha_token}"/>
                <input type="hidden" name="txnid" value="${paymentDetails.txnid}"/>
                <input type="hidden" name="productinfo" value="${paymentDetails.plan_name}"/>
                <input type="hidden" name="amount" value="${paymentDetails.amount}"/>
                <input type="hidden" name="service_provider" value="${paymentDetails.service_provider}"/>
                <button type="submit" value="submit" #submitBtn></button>
              </form>
              <script type="text/javascript">document.getElementById("payu_form").submit();</script>
            </body>
          </html>`;

    const winUrl = URL.createObjectURL(
      new Blob([paymentString], { type: 'text/html' })
    );

    window.location.href = winUrl;
    // });
  }
}
