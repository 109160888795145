import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'es', 'de', 'it', 'ru'];

  constructor(public translate: TranslateService, private cookieService: CookieService) {
    this.translate.addLangs(this.languages);
    let browserLang: string | undefined = '';

    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    } else {
      browserLang = this.translate.getBrowserLang();
    }

    // If browserLang is not in the supported languages, fallback to 'en'
    const selectedLang = browserLang && this.languages.includes(browserLang) ? browserLang : 'en';
    this.translate.use(selectedLang);
    this.cookieService.set('lang', selectedLang);
  }

  public setLanguage(lang: string) {
    if (this.languages.includes(lang)) {
      this.translate.use(lang);
      this.cookieService.set('lang', lang);
    } else {
      console.error(`Language '${lang}' is not supported.`);
    }
  }
}
