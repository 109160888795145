// masters.effects.ts
import { MasterService } from './../../core/services/master.services';
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import {
  CreateMasterFailure,
  CreateMasterSuccess,
  GetAllmaster,
  Getmaster,
  createMaster,
  deleteMaster,
  deleteMasterFailure,
  deleteMasterSuccess,
  getAllMastersFailure,
  getAllMastersSuccess,
  getMastersFailure,
  getMastersSuccess,
  updateMaster,
  updateMasterFailure,
  updateMasterSuccess,
} from './masters.actions';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
@Injectable()
export class MastersModuleEffects {
  ServiceError = Validations.ServiceError;
  createMaster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMaster),
      exhaustMap((masterData) => {
        console.log(masterData);
        this.loaderService.setLoading(true);
        return this.masterService.createMasters(masterData.masterData).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return CreateMasterSuccess({ createMasterResponse: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return CreateMasterFailure({ createMasterError: response });
            }
          }),
          catchError((error) => {
            console.log(error);

            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(CreateMasterFailure({ createMasterError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  updateMaster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMaster),
      exhaustMap((masterData, id) => {
        console.log(masterData, '-----', id);
        this.loaderService.setLoading(true);
        return this.masterService
          .updateMaster(masterData.masterData, masterData.id)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 200) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                // return updateMasterSuccess({ updateMasterResponse: response })
                return Getmaster({
                  pageIndex: 1,
                  pageSize: 10,
                  searchTerm: '',
                });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return updateMasterFailure({ updateMasterError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(CreateMasterFailure({ createMasterError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  deleteMaster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMaster),
      exhaustMap((masterData, id) => {
        console.log(masterData, '-----', id);
        this.loaderService.setLoading(true);
        return this.masterService.deleteMaster(masterData.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              // return deleteMasterSuccess({ deleteMasterResponse: response })
              return Getmaster({ pageIndex: 1, pageSize: 10, searchTerm: '' });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteMasterFailure({ deleteMasterError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(deleteMasterFailure({ deleteMasterError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getMaster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Getmaster),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.masterService
          .getMasters(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getMastersSuccess({ getMasterResponse: response.data });
              } else {
                return getMastersFailure({ getMasterError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getMastersFailure({ getMasterError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  GetAllmaster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllmaster),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.masterService
          .getMasters(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllMastersSuccess({
                  getAllMasterResponse: response.data,
                });
              } else {
                return getAllMastersFailure({ getAllMasterError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getAllMastersFailure({ getAllMasterError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private masterService: MasterService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) {}
}
