// devices.effects.ts
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import {
  CreatedeviceFailure,
  CreatedeviceSuccess,
  GetAlldevice,
  createdevice,
  deletedevice,
  deletedeviceFailure,
  deletedeviceSuccess,
  getAlldevicesFailure,
  getAlldevicesSuccess,
  getDevices,
  updatedevice,
  updatedeviceFailure,
  updatedeviceSuccess,
} from './iot-devices.actions';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { DeviceService } from '../../core/services/deivce.services';
import { getDevicessFailure, getDevicessSuccess } from './iot-devices.actions';
@Injectable()
export class DevicesModuleEffects {
  ServiceError = Validations.ServiceError;
  createdevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createdevice),
      exhaustMap((deviceData) => {
        console.log(deviceData);
        this.loaderService.setLoading(true);
        return this.deviceService.createdevices(deviceData.deviceData).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return CreatedeviceSuccess({ createdeviceResponse: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return CreatedeviceFailure({ createdeviceError: response });
            }
          }),
          catchError((error) => {
            console.log(error);

            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(CreatedeviceFailure({ createdeviceError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  updatedevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatedevice),
      exhaustMap((deviceData, id) => {
        console.log(deviceData, '-----', id);
        this.loaderService.setLoading(true);
        return this.deviceService
          .updateDevice(deviceData.deviceData, deviceData.id)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 200) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                return updatedeviceSuccess({ updatedeviceResponse: response });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return updatedeviceFailure({ updatedeviceError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(CreatedeviceFailure({ createdeviceError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  deletedevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletedevice),
      exhaustMap((deviceData, id) => {
        console.log(deviceData, '-----', id);
        this.loaderService.setLoading(true);
        return this.deviceService.deletedevice(deviceData.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return deletedeviceSuccess({ deletedeviceResponse: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deletedeviceFailure({ deletedeviceError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(deletedeviceFailure({ deletedeviceError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getdevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDevices),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.deviceService
          .getdevices(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getDevicessSuccess({
                  getDevicesResponse: response.data,
                });
              } else {
                return getDevicessFailure({ getDevicesError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getDevicessFailure({ getDevicesError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  GetAlldevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAlldevice),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.deviceService
          .getdevices(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAlldevicesSuccess({
                  getAlldeviceResponse: response.data,
                });
              } else {
                return getAlldevicesFailure({ getAlldeviceError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getAlldevicesFailure({ getAlldeviceError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private deviceService: DeviceService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) {}
}
