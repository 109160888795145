// masters.effects.ts
import { MasterService } from '../../core/services/master.services';
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import {
  CreateSupplierFailure,
  CreateSupplierSuccess,
  GetAllSuppliers,
  GetSuppliers,
  createSupplier,
  deleteSupplier,
  deleteSupplierFailure,
  getAllSuppliersFailure,
  getAllSuppliersSuccess,
  getSuppliersFailure,
  getSuppliersSuccess,
  updateSupplier,
  updateSupplierFailure,
} from './suppliers.actions';
import { SuppliersService } from '../../core/services/suppliers.service';
@Injectable()
export class SuppliersModuleEffects {
  ServiceError = Validations.ServiceError;
  createSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSupplier),
      exhaustMap((supplierData) => {
        console.log(supplierData);
        this.loaderService.setLoading(true);
        return this.supplierService
          .createSupplier(supplierData.supplierData)
          .pipe(
            map((response: any) => {
              console.log(response);
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 201) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                // return CreateSupplierSuccess({ createSupplierResponse: response })
                return GetSuppliers({
                  pageIndex: 1,
                  pageSize: 10,
                  searchTerm: '',
                });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return CreateSupplierFailure({ createSupplierError: response });
              }
            }),
            catchError((error) => {
              console.log(error);

              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(CreateSupplierFailure({ createSupplierError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  updateSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSupplier),
      exhaustMap((supplierData, id) => {
        console.log(supplierData, '-----', id);
        this.loaderService.setLoading(true);
        return this.supplierService
          .updateSupplier(supplierData.supplierData, supplierData.id)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 200) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                // return updateMasterSuccess({ updateMasterResponse: response })
                return GetSuppliers({
                  pageIndex: 1,
                  pageSize: 10,
                  searchTerm: '',
                });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return updateSupplierFailure({ updateSupplierError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(updateSupplierFailure({ updateSupplierError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  deleteSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSupplier),
      exhaustMap((masterData, id) => {
        console.log(masterData, '-----', id);
        this.loaderService.setLoading(true);
        return this.supplierService.deleteSupplier(masterData.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              // return deleteMasterSuccess({ deleteMasterResponse: response })
              return GetSuppliers({
                pageIndex: 1,
                pageSize: 10,
                searchTerm: '',
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteSupplierFailure({ deleteSupplierError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(deleteSupplierFailure({ deleteSupplierError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  GetSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetSuppliers),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.supplierService
          .getSuppliers(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getSuppliersSuccess({
                  getSuppliersResponse: response.data,
                });
              } else {
                return getSuppliersFailure({ getSuppliersError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getSuppliersFailure({ getSuppliersError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  GetAllSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllSuppliers),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.supplierService
          .getSuppliers(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllSuppliersSuccess({
                  getAllSuppliersResponse: response.data,
                });
              } else {
                return getAllSuppliersFailure({
                  getAllSuppliersError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getSuppliersFailure({ getSuppliersError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private supplierService: SuppliersService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) {}
}
