// masters.effects.ts
import { MasterService } from '../../core/services/master.services';
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import { PayloadConfigService } from '../../core/services/payloadConfig.services';
import {
  getResponseConfigsFailure,
  getResponseConfigsSuccess,
} from './payloadConfig.actions';
import {
  CreatepayloadConfigFailure,
  CreatepayloadConfigSuccess,
  deleteResponseConfigAction,
  deleteResponseConfigFailure,
  deleteResponseConfigSuccess,
  getAllpayloadConfig,
  getAllpayloadConfigsFailure,
  getAllpayloadConfigsSuccess,
  GetResponseConfig,
  updatepayloadConfig,
  updatepayloadConfigFailure,
  updatepayloadConfigSuccess,
} from './payloadConfig.actions';
import { createpayloadConfig } from './payloadConfig.actions';
@Injectable()
export class PayloadConfigsModuleEffects {
  ServiceError = Validations.ServiceError;

  getResponseConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetResponseConfig),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.payloadConfigService
          .getResponseConfig(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getResponseConfigsSuccess({
                  getresponseConfigResponse: response.data,
                });
              } else {
                return getResponseConfigsFailure({
                  getresponseConfigError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getResponseConfigsFailure({ getresponseConfigError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  createResponseConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createpayloadConfig),
      exhaustMap((payloadConfigData) => {
        console.log(payloadConfigData);
        this.loaderService.setLoading(true);
        return this.payloadConfigService
          .createResponseConfig(payloadConfigData.payloadConfigData)
          .pipe(
            map((response: any) => {
              console.log(response);
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 201) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                this.router.navigate(['/configuration']);
                return CreatepayloadConfigSuccess({
                  createpayloadConfigResponse: response,
                });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return CreatepayloadConfigFailure({
                  createpayloadConfigError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);

              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                CreatepayloadConfigFailure({ createpayloadConfigError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  updatepayloadConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatepayloadConfig),
      exhaustMap(({ payloadConfigData, id }) => {
        console.log(payloadConfigData, '-----', id);
        this.loaderService.setLoading(true);
        return this.payloadConfigService
          .updatePayloadConfig(payloadConfigData, id)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              if (response && response.statusCode === 200) {
                this.sharedService.showMessageDialog(
                  'Success',
                  response.message,
                  SweetAlertIcon.SUCCESS
                );
                this.router.navigate(['/configuration']);
                return updatepayloadConfigSuccess({
                  updatepayloadConfigResponse: response,
                });
              } else {
                this.sharedService.showMessageDialog(
                  'Failure',
                  response.message,
                  SweetAlertIcon.ERROR
                );
                return updatepayloadConfigFailure({
                  updatepayloadConfigError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                updatepayloadConfigFailure({ updatepayloadConfigError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  deleteResponseConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteResponseConfigAction),
      exhaustMap((payload, id) => {
        console.log(payload, '-----', id);
        this.loaderService.setLoading(true);
        return this.payloadConfigService.deleteResponseConfig(payload.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return deleteResponseConfigSuccess({
                deleteResponseConfigResponse: response,
              });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteResponseConfigFailure({
                deleteResponseConfigError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              deleteResponseConfigFailure({ deleteResponseConfigError: error })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getAllpayloadConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllpayloadConfig),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.payloadConfigService
          .getPayloadConfig(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllpayloadConfigsSuccess({
                  getallpayloadConfigResponse: response.data,
                });
              } else {
                return getAllpayloadConfigsFailure({
                  getallpayloadConfigError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getAllpayloadConfigsFailure({ getallpayloadConfigError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private payloadConfigService: PayloadConfigService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) {}
}
