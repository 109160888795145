import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  x: any = null;
  currentMessage = new BehaviorSubject(this.x);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId) && 'serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        console.log('Service Worker Message Received: ', event.data);
        this.currentMessage.next(event.data);
      });
    }
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe({
      next: (token) => {
        console.log(token);
      },
      error: (err) => {
        console.log('unable to get permission to notify....', err);
      },
    });
  }
  
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log('new message received. ', payload);
      this.currentMessage.next(payload);
    });
  }

  getNewNotification(): Observable<any> {
    return this.currentMessage.asObservable();
  }
}
