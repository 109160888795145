import { createReducer, on } from '@ngrx/store';
import { PayloadConfig } from './payloadConfig.models';
import {
  getAllpayloadConfig,
  getAllpayloadConfigsFailure,
  getAllpayloadConfigsSuccess,
} from './payloadConfig.actions';
import {
  deleteResponseConfigSuccess,
  deleteResponseConfigFailure,
} from './payloadConfig.actions';
import {
  createpayloadConfig,
  CreatepayloadConfigSuccess,
  CreatepayloadConfigFailure,
  deleteResponseConfigAction,
} from './payloadConfig.actions';
import {
  GetResponseConfig,
  getResponseConfigsSuccess,
  getResponseConfigsFailure,
} from './payloadConfig.actions';

export interface payloadConfigState {
  payloadConfig: PayloadConfig | null;
  createpayloadConfigError: any;
  getresponseConfigResponse: any;
  createpayloadConfigResponse: any;
  deleteResponseConfigResponse: any;
  getallpayloadConfigResponse: any;
}

const initialState: payloadConfigState = {
  payloadConfig: null,
  createpayloadConfigError: null,
  getresponseConfigResponse: null,
  createpayloadConfigResponse: null,
  deleteResponseConfigResponse: null,
  getallpayloadConfigResponse: null,
};

export const payloadConfigReducer = createReducer(
  initialState,
  on(GetResponseConfig, (state) => ({ ...state })),
  on(getResponseConfigsSuccess, (state, { getresponseConfigResponse }) => ({
    ...state,
    getresponseConfigResponse,
  })),
  on(getResponseConfigsFailure, (state, { getresponseConfigError }) => ({
    ...state,
    getresponseConfigError,
  })),

  on(createpayloadConfig, (state) => ({ ...state, error: null })),
  on(CreatepayloadConfigSuccess, (state, { createpayloadConfigResponse }) => ({
    ...state,
    createpayloadConfigResponse,
  })),
  on(CreatepayloadConfigFailure, (state, { createpayloadConfigError }) => ({
    ...state,
    createpayloadConfigError,
  })),
  on(deleteResponseConfigAction, (state) => ({ ...state, error: null })),
  on(
    deleteResponseConfigSuccess,
    (state, { deleteResponseConfigResponse }) => ({
      ...state,
      deleteResponseConfigResponse,
    })
  ),
  on(deleteResponseConfigFailure, (state, { deleteResponseConfigError }) => ({
    ...state,
    deleteResponseConfigError,
  })),
  on(getAllpayloadConfig, (state) => ({ ...state, error: null })),
  on(getAllpayloadConfigsSuccess, (state, { getallpayloadConfigResponse }) => ({
    ...state,
    getallpayloadConfigResponse,
  })),
  on(getAllpayloadConfigsFailure, (state, { getallpayloadConfigError }) => ({
    ...state,
    getallpayloadConfigError,
  }))
);
