import { createReducer, on } from '@ngrx/store';
import {
  getUserProfile,
  getUserProfileFailure,
  getUserProfileSuccess,
  login,
  loginFailure,
  loginSuccess,
  logout,
  logoutFailure,
  logoutSuccess,
  sendOtp,
  sendOtpFailure,
  sendOtpSuccess,
  refreshTokenSuccess,
  refreshTokenFailure,
  getUpdatedUserProfileSuccess,
  resetProfileState,
} from './authentication.actions';
import { User } from './auth.models';

export interface AuthenticationState {
  isLoggedIn: boolean;
  user: User | null;
  error: string | null;
  sendOtpResponse: any;
  sendOtpError: any;
  loading: boolean;
  userDetails: any;
  getUserProfileFailureError: any;
  refreshTokenRes: any;
  loginSuccessRes: any;
  getUpdatedUserProfileResponse: any;
  getUpdatedUserProfileResponseReset: any;
}

const initialState: AuthenticationState = {
  isLoggedIn: false,
  user: null,
  error: null,
  sendOtpResponse: null,
  sendOtpError: null,
  loading: false,
  userDetails: null,
  getUserProfileFailureError: null,
  refreshTokenRes: null,
  loginSuccessRes: null,
  getUpdatedUserProfileResponse: null,
  getUpdatedUserProfileResponseReset: null,
};

export const authenticationReducer = createReducer(
  initialState,
  // on(Register, (state) => ({ ...state, error: null })),
  // on(RegisterSuccess, (state, { user }) => ({ ...state, isLoggedIn: true, user, error: null, })),
  // on(RegisterFailure, (state, { error }) => ({ ...state, error })),

  on(login, (state) => ({ ...state, error: null, loading: true })),
  on(loginSuccess, (state, { user }) => ({
    ...state,
    isLoggedIn: true,
    user,
    error: null,
    loading: false,
  })),
  on(loginFailure, (state, { error }) => ({ ...state, error, loading: false })),

  /*  on(login, state => ({ ...state })),
     on(loginSuccess, (state, { loginSuccessRes }) => ({ ...state, loginSuccessRes })),
     on(loginFailure, (state, { error }) => ({ ...state, error })), */

  on(logout, (state) => ({ ...state, user: null, loading: false })),
  on(logout, logoutSuccess, (state) => ({
    ...state,
    user: null,
    loading: false,
  })),
  on(logoutFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(sendOtp, (state) => ({ ...state, error: null, loading: true })),
  on(sendOtpSuccess, (state, { sendOtpResponse }) => ({
    ...state,
    sendOtpResponse,
    error: null,
    loading: false,
  })),
  on(sendOtpFailure, (state, { sendOtpError }) => ({
    ...state,
    sendOtpError,
    loading: false,
  })),

  on(getUserProfile, (state) => ({ ...state, error: null, loading: true })),
  on(getUserProfileSuccess, (state, { userDetails }) => ({
    ...state,
    userDetails,
    error: null,
    loading: false,
  })),
  on(getUserProfileFailure, (state, { getUserProfileFailureError }) => ({
    ...state,
    getUserProfileFailureError,
    loading: false,
  })),
  on(
    getUpdatedUserProfileSuccess,
    (state, { getUpdatedUserProfileResponse }) => ({
      ...state,
      getUpdatedUserProfileResponse,
    })
  ),

  on(getUserProfile, (state) => ({ ...state, error: null, loading: true })),
  on(refreshTokenSuccess, (state, { refreshTokenRes }) => ({
    ...state,
    refreshTokenRes,
    error: null,
    loading: false,
  })),
  on(refreshTokenFailure, (state, { refreshTokenerror }) => ({
    ...state,
    refreshTokenerror,
    loading: false,
  })),
  on(resetProfileState, (state) => ({
    ...state,
    getUpdatedUserProfileResponseReset: null,
  }))
);
