import { ActionReducerMap } from '@ngrx/store';
import {
  AuthenticationState,
  authenticationReducer,
} from './Authentication/authentication.reducer';
import { LayoutState, layoutReducer } from './layouts/layouts.reducer';
import { MasterState, masterReducer } from './Masters/masters.reducer';
import { SupplierState, SuppliersReducer } from './Suppliers/suppliers.reducer';
import {
  payloadConfigState,
  payloadConfigReducer,
} from './payload_Config/payloadConfig.reducer';
import {
  DevicesState,
  DevicesReducer,
} from './iot-devices/iot-devices.reducer';
import {
  ReadingsReducer,
  ReadingsState,
} from './iot-readings/iot-readings.reducer';
export interface RootReducerState {
  layout: LayoutState;
  auth: AuthenticationState;
  master: MasterState;
  supplier: SupplierState;
  payloadConfig: payloadConfigState;
  device: DevicesState;
  readings: ReadingsState;
}

export const rootReducer: ActionReducerMap<RootReducerState> = {
  layout: layoutReducer,
  auth: authenticationReducer,
  master: masterReducer,
  supplier: SuppliersReducer,
  payloadConfig: payloadConfigReducer,
  device: DevicesReducer,
  readings: ReadingsReducer,
};
