<app-rp-loader></app-rp-loader>
<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">

        <a class="logo logo-light" (click)="gotoDashBoard()">
          <span class="logo-sm">
            <img src="../../../assets/images/ripple_logo_mini.png" alt="" height="35">
          </span>
          <span class="logo-lg">
            <img src="../../../assets/images/ripple_full_logo_white.png" alt="" height="50">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item"
        [ngClass]="{ 'd-none': theme === 'horizontal' }" id="vertical-menu-btn" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <span class="d-flex align-items-center d-lg-none d-sm-block">
        <img src="../../../assets/images/ripple_full_logo_color.png" alt="" height="40">
      </span>

    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" dropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" dropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" *dropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>



      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" dropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user " [src]="profileData?.fileUrl  || 'assets/images/users/user-dummy-img.jpg'"  alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{profileData?.firstName}} {{profileData?.lastName}} </span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a class="dropdown-item" data-bs-toggle="modal" data-bs-target=".bs-example-modal-center"
            (click)="openProfileModal()"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            Profile</a>
          <a class="dropdown-item d-block" href="javascript: void(0);" data-bs-toggle="modal"
            data-bs-target=".bs-example-modal-center" (click)="openChangePwdModal()"><i
              class="bx bx-lock font-size-16 align-middle me-1"></i>
            Change password</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Logout</a>
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
            <i class="bx bx-cog bx-spin"></i>
          </button>
        </div> -->

    </div>
  </div>
</header>



<!-- Change password modal -->
<div bsModal #centerDataModal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Change password</h5>
        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
          (click)="centerDataModal?.hide()"></button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal" (ngSubmit)="onSubmit()">
          <div class="mb-3">
            <label>Enter Old Password</label>
            <input type="text" class="form-control" />

          </div>

          <div class="mb-3">
            <label>Enter New Password</label>
            <input type="text" class="form-control" />

          </div>

          <div class="mb-3">
            <label>Re-enter New Password</label>
            <input type="text" class="form-control" />

          </div>
        </form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light">Save</button>
      </div>
    </div><!-- /.modal -->
  </div>
</div>
<!-- Change password modal End  -->


<!-- Profile modal -->
<div bsModal #profileModal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Profile</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="profileModal?.hide()"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 text-center">

            <div class="profile-container">
              <label class="hoverable" for="fileInput">
                <img [src]="url ? url : '../../../assets/images/users/avatar-2.jpg'" />
                <div class="background"></div>
              </label>
              <br />
              <input id="fileInput" type="file" [(ngModel)]="url" class="select-profile-picture"
                (change)="onSelectFile($event)" />
              <span class="message text-primary text-decoration-underline font-size-16">Change picture</span>
            </div>


          </div>
          <div class="col-sm-12 col-md-8 col-lg-8">

            <form (ngSubmit)="onSubmit()">
              <div class="row">

                <div class="mb-3 col-lg-6">
                  <label>First Name</label>
                  <input type="text" placeholder="Enter First Name" class="form-control" />

                </div>

                <div class="mb-3 col-lg-6">
                  <label>Last Name</label>
                  <input type="text" placeholder="Enter Last Name" class="form-control" />

                </div>

                <div class="mb-3 col-lg-6">
                  <label>Email Address</label>
                  <input type="text" placeholder="Enter Email Address" class="form-control" />

                </div>
                <div class="mb-3 col-lg-6">
                  <label>Mobile Number</label>
                  <input type="text" placeholder="Enter Mobile Number" class="form-control" />

                </div>
                <div class="mb-3 col-lg-6">
                  <label>Second Mobile Number</label>
                  <input type="text" placeholder="Enter Second Mobile Number" class="form-control" />

                </div>
              </div>
            </form>

          </div>
        </div>

      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light">Save</button>
      </div>
    </div><!-- /.modal -->
  </div>
</div>
<!-- Profile modal End  -->