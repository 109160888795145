import { createReducer, on } from '@ngrx/store';
import {
  GetAllrawDataSuccess,
  resetExportedData,
} from './iot-readings.actions';
import {
  getAllreadingssSuccess,
  getAllArchivedDataSuccess,
} from './iot-readings.actions';
import {
  getAllElectricStatussSuccess,
  getAllEb_Dg_StatusSuccess,
} from './iot-readings.actions';
import {
  getEb_Dg_StatusSuccess,
  getElectricStatussSuccess,
} from './iot-readings.actions';
import {
  getArchivedData,
  getArchivedDataFailure,
  getArchivedDataSuccess,
  GetrawData,
  getrawDataFailure,
  getrawDataSuccess,
  getreadings,
  getreadingssFailure,
  getreadingssSuccess,
} from './iot-readings.actions';

export interface ReadingsState {
  getreadingsResponse: any;
  getrawDataSuccessResponse: any;
  getArchivedResponse: any;
  getElectricStatusResponse: any;
  getEb_Dg_StatusResponse: any;
  getAllElectricStatusResponse: any;
  getAllEb_Dg_StatusResponse: any;
  getAllreadingsResponse: any;
  getAllArchivedResponse: any;
  GetAllrawDataSuccessResponse: any;
}

const initialState: ReadingsState = {
  getreadingsResponse: null,
  getrawDataSuccessResponse: null,
  getArchivedResponse: null,
  getElectricStatusResponse: null,
  getEb_Dg_StatusResponse: null,
  getAllElectricStatusResponse: null,
  getAllEb_Dg_StatusResponse: null,
  getAllreadingsResponse: null,
  getAllArchivedResponse: null,
  GetAllrawDataSuccessResponse: null,
};

export const ReadingsReducer = createReducer(
  initialState,
  on(getreadings, (state) => ({ ...state })),

  on(getreadingssSuccess, (state, { getreadingsResponse }) => ({
    ...state,
    getreadingsResponse,
  })),
  on(getreadingssFailure, (state, { getreadingsError }) => ({
    ...state,
    getreadingsError,
  })),

  on(getAllreadingssSuccess, (state, { getAllreadingsResponse }) => ({
    ...state,
    getAllreadingsResponse,
  })),

  on(GetrawData, (state) => ({ ...state })),

  on(getrawDataSuccess, (state, { getrawDataSuccessResponse }) => ({
    ...state,
    getrawDataSuccessResponse,
  })),

  on(getrawDataFailure, (state, { getrawDataeError }) => ({
    ...state,
    getrawDataeError,
  })),
  on(GetAllrawDataSuccess, (state, { GetAllrawDataSuccessResponse }) => ({
    ...state,
    GetAllrawDataSuccessResponse,
  })),
  on(getArchivedData, (state) => ({ ...state })),

  on(getArchivedDataSuccess, (state, { getArchivedResponse }) => ({
    ...state,
    getArchivedResponse,
  })),
  on(getArchivedDataFailure, (state, { getArchivedeError }) => ({
    ...state,
    getArchivedeError,
  })),

  on(getAllArchivedDataSuccess, (state, { getAllArchivedResponse }) => ({
    ...state,
    getAllArchivedResponse,
  })),
  on(getElectricStatussSuccess, (state, { getElectricStatusResponse }) => ({
    ...state,
    getElectricStatusResponse,
  })),
  on(
    getAllElectricStatussSuccess,
    (state, { getAllElectricStatusResponse }) => ({
      ...state,
      getAllElectricStatusResponse,
    })
  ),
  on(getEb_Dg_StatusSuccess, (state, { getEb_Dg_StatusResponse }) => ({
    ...state,
    getEb_Dg_StatusResponse,
  })),

  on(getAllEb_Dg_StatusSuccess, (state, { getAllEb_Dg_StatusResponse }) => ({
    ...state,
    getAllEb_Dg_StatusResponse,
  })),
  // on(resetDevicesState, () => initialState)

  on(resetExportedData, (state) => ({
    ...state,
    GetAllrawDataSuccessResponse: null,
    getAllreadingsResponse: null,
    getAllArchivedResponse: null,
  }))
);
