import { createReducer, on } from '@ngrx/store';
import { Supplier } from './suppliers.models';
import {
  GetAllSuppliers,
  getAllSuppliersFailure,
  getAllSuppliersSuccess,
  GetSuppliers,
  getSuppliersFailure,
  getSuppliersSuccess,
} from './suppliers.actions';

export interface SupplierState {
  master: Supplier | null;
  createMasterError: any;
  getSuppliersResponse: any;
  createSuppliersResponse: any;
  getAllSuppliersResponse: any;
}

const initialState: SupplierState = {
  master: null,
  createMasterError: null,
  getSuppliersResponse: null,
  createSuppliersResponse: null,
  getAllSuppliersResponse: null,
};

export const SuppliersReducer = createReducer(
  initialState,
  on(GetSuppliers, (state) => ({ ...state })),
  on(getSuppliersSuccess, (state, { getSuppliersResponse }) => ({
    ...state,
    getSuppliersResponse,
  })),
  on(getSuppliersFailure, (state, { getSuppliersError }) => ({
    ...state,
    getSuppliersError,
  })),
  on(GetAllSuppliers, (state) => ({ ...state })),
  on(getAllSuppliersSuccess, (state, { getAllSuppliersResponse }) => ({
    ...state,
    getAllSuppliersResponse,
  })),
  on(getAllSuppliersFailure, (state, { getAllSuppliersError }) => ({
    ...state,
    getAllSuppliersError,
  }))
);
